type MakeRequestParam<T> = T extends undefined ? undefined : T
export async function makeRequestWithErrorCallback<T>(
  request: MakeRequestParam<T>,
  errorCallback: () => void
): Promise<MakeRequestParam<T>> {
  if (!request) {
    return request
  }
  try {
    return await request
  } catch (e) {
    errorCallback()
    throw e
  }
}
