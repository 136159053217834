export function UUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function imageStringToImage(
  imageString: string
): Promise<HTMLImageElement> {
  return new Promise<HTMLImageElement>((resolve) => {
    const image = new Image()
    image.onload = () => {
      resolve(image)
    }
    image.src = imageString
  })
}

export async function resizeImage(
  imageString: string,
  width: number,
  height: number
) {
  const image = await imageStringToImage(imageString)
  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height
  const ctx = canvas.getContext('2d')
  if (!ctx) {
    return ''
  }
  ctx.drawImage(image, 0, 0, width, height)
  return canvas.toDataURL('image/jpeg')
}

export async function responseToDataUri(response: Response): Promise<string> {
  const blob = await response.blob()
  return await new Promise((callback) => {
    const reader = new FileReader()
    reader.onload = function () {
      callback(this.result as string)
    }
    reader.readAsDataURL(blob)
  })
}
