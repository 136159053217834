import { gsap } from 'gsap'
export function moveFromTo(
  element: HTMLElement,
  oldRect: DOMRect,
  newRect: DOMRect
) {
  gsap.fromTo(
    element,
    {
      x: oldRect.left - newRect.left,
      y: oldRect.top - newRect.top,
      width: oldRect.width,
      height: oldRect.height,
      duration: 0.5
    },
    {
      x: 0,
      y: 0,
      width: newRect.width,
      height: newRect.height
    }
  )
}

export function clearMoveElement(element: HTMLElement) {
  gsap.set(element, {
    x: 0,
    y: 0,
    height: 'auto',
    width: 'auto'
  })
}
