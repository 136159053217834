<script setup lang="ts">
import { SpotifyApi } from '@spotify/web-api-ts-sdk'
import { onMounted, ref } from 'vue'
import SimpleButton from './common/SimpleButton.vue'
defineProps<{
  loading: boolean
}>()
const emit = defineEmits<{
  (e: 'loadedSdk', sdk: SpotifyApi): void
}>()
const internalLoading = ref(false)
onMounted(async () => {
  const code = new URLSearchParams(location.search).get('code')
  if (code) {
    loginWithSpotify()
  }
})
async function loginWithSpotify() {
  internalLoading.value = true
  const sdk = SpotifyApi.withUserAuthorization(
    import.meta.env.VITE_SPOTIFY_CLIENT_ID,
    import.meta.env.VITE_REDIRECT_TARGET,
    [
      'playlist-read-private',
      'ugc-image-upload',
      'playlist-modify-private',
      'playlist-modify-public'
    ]
  )
  await sdk.authenticate()
  emit('loadedSdk', sdk)
  internalLoading.value = false
}
</script>

<template>
  <div class="mx-auto max-w-[600px] w-full">
    <div
      class="bg-white text-slate-800 rounded flex flex-col overflow-hidden p-4"
    >
      <h1 class="mb-2 text-2xl">Spotify Playlist Cover Generator</h1>
      <p class="mb-4">
        Authenticate with Spotify to generate a playlist cover based on the
        playlist's genres, artists or title.
      </p>
      <simple-button
        :loading="loading || internalLoading"
        primary
        @click="loginWithSpotify"
        >Authenticate with Spotify
      </simple-button>
    </div>
  </div>
</template>

<style scoped></style>
