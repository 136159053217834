<script setup lang="ts">
import { ref } from 'vue'
import SimpleButton from './SimpleButton.vue'
defineProps<{
  modelValue: string
  options: string[]
  icon?: string
  unstyled?: boolean
}>()
defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const opener = ref<HTMLSpanElement>()
const width = ref(0)
const updateWidth = () => {
  console.log(width, opener.value?.offsetWidth)
  width.value = opener.value?.offsetWidth ?? 0
}
</script>

<template>
  <VDropdown>
    <span ref="opener">
      <simple-button
        v-if="!unstyled"
        class="w-full"
        :icon="icon"
        @click="updateWidth"
        >{{ modelValue }}
      </simple-button>
      <button v-else @click="updateWidth">
        {{ modelValue }}
      </button>
    </span>
    <template #popper>
      <div
        class="flex flex-col max-h-40 min-w-20 rounded"
        :style="{ width: `${width}px` }"
      >
        <template v-for="option in options" :key="option">
          <simple-button
            v-if="!unstyled"
            v-close-popper
            class="rounded-none border-b border-black"
            @mousedown="$emit('update:modelValue', option)"
            >{{ option }}
          </simple-button>
          <button
            v-else
            v-close-popper
            class="hover:bg-slate-200 p-2"
            @mousedown="$emit('update:modelValue', option)"
          >
            {{ option }}
          </button>
        </template>
      </div>
    </template>
  </VDropdown>
</template>

<style>
.v-popper__arrow-container {
  display: none;
}
</style>
