<script setup lang="ts">
import { nextTick, ref } from 'vue'
import SimpleButton from './components/common/SimpleButton.vue'
import DropDown from './components/common/DropDown.vue'
import {
  SpotifyApi,
  type SimplifiedPlaylist,
  type Page,
  type PlaylistedTrack,
  type Track,
  type Artist,
  type SimplifiedArtist
} from '@spotify/web-api-ts-sdk'
import { resizeImage } from './utils/utils'
import { makeRequestWithErrorCallback } from './utils/sdk-utils'
import AuthenticatePage from './components/AuthenticatePage.vue'
import TrackList from './components/TrackList.vue'
import { type GenerateType } from './interfaces/generate'
import { useGenerate } from './models/use-generate'
import { moveFromTo, clearMoveElement } from './utils/gsap-utils'
let sdk: SpotifyApi | undefined = undefined

const loading = ref(false)
const playlists = ref<Page<SimplifiedPlaylist>>()
const playlistElements = ref<HTMLDivElement[]>([])
const playlist = ref<SimplifiedPlaylist>()
const targetPlaylistContainer = ref<HTMLDivElement>()
const tracks = ref<Page<PlaylistedTrack<Track>>>()
const artists = ref<Artist[]>()

function logout() {
  closePlaylist()
  playlists.value = undefined
  if (!sdk) {
    return
  }
  sdk.logOut()
}

async function loadedSdk(newSdk: SpotifyApi) {
  loading.value = true
  sdk = newSdk
  await getPlaylists()
  loading.value = false
}

async function getPlaylists() {
  playlists.value = await makeRequestWithErrorCallback(
    sdk?.currentUser.playlists.playlists(),
    logout
  )
}
let playlistIndex = -1
const playlistsContainer = ref<HTMLDivElement>()
async function selectPlaylist(_playlist: SimplifiedPlaylist, index: number) {
  const playlistElement = playlistElements.value[index]
  clearMoveElement(playlistElement)
  var oldRect = playlistElement.getBoundingClientRect()
  playlistIndex = index
  playlist.value = _playlist
  getTracks()
  await nextTick()
  targetPlaylistContainer.value?.append(playlistElement)
  var newRect = playlistElement.getBoundingClientRect()
  moveFromTo(playlistElement, oldRect, newRect)
}

async function closePlaylist() {
  if (targetPlaylistContainer.value && playlistsContainer.value) {
    const playlistElement = playlistElements.value[playlistIndex]
    const nextPlaylistElement = playlistElements.value[playlistIndex + 1]
    var oldRect = playlistElement.getBoundingClientRect()
    if (nextPlaylistElement) {
      playlistsContainer.value.insertBefore(
        playlistElement,
        nextPlaylistElement
      )
    } else {
      playlistsContainer.value.appendChild(playlistElement)
    }
    playlist.value = undefined
    loadingImage.value = false
    image.value = ''
    tracks.value = undefined
    done.value = false
    await nextTick()
    clearMoveElement(playlistElement)
    var newRect = playlistElement.getBoundingClientRect()
    moveFromTo(playlistElement, oldRect, newRect)
  }
  await nextTick()
}

async function getTracks() {
  if (!playlist.value || !sdk) {
    return
  }
  tracks.value = await makeRequestWithErrorCallback(
    sdk.playlists.getPlaylistItems(playlist.value?.id),
    logout
  )
  const artistsArray = tracks.value.items.flatMap(
    (track) => track.track.artists
  )
  artists.value = []
  await getArtists(artistsArray)
}

async function getArtists(artistsArray: SimplifiedArtist[]) {
  if (!sdk) {
    return
  }
  const currentArtists = artistsArray.splice(0, 50)
  artists.value = [
    ...(await makeRequestWithErrorCallback(
      sdk.artists.get(currentArtists.map((artist) => artist.id)),
      logout
    ))
  ]
  if (artistsArray.length) {
    getArtists(artistsArray)
  }
}

const options: GenerateType[] = ['genres', 'artists', 'title']
const focus = ref<GenerateType>('genres')
const { generateCoverImage } = useGenerate(playlist, tracks, artists, focus)
const image = ref('')
const loadingImage = ref(false)
async function generateCover() {
  image.value = ''
  loadingImage.value = true
  done.value = false
  image.value = await generateCoverImage()
  loadingImage.value = false
}

const sendingImage = ref(false)
const done = ref(false)
async function useImage() {
  if (!sdk || !playlist.value || !image.value) {
    return
  }
  sendingImage.value = true
  const newImage = await resizeImage(image.value, 256, 256)
  await makeRequestWithErrorCallback(
    sdk.playlists.addCustomPlaylistCoverImageFromBase64String(
      playlist.value.id,
      newImage.substring('data:image/jpeg;base64,'.length)
    ),
    logout
  )
  done.value = true
  sendingImage.value = false
}
</script>
<template>
  <main
    class="h-screen w-screen overflow-auto bg-slate-200 flex flex-col gap-5 p-10 text-slate-200"
  >
    <authenticate-page
      v-if="!playlists"
      :loading="loading"
      @loaded-sdk="loadedSdk"
    />
    <div
      v-if="playlists"
      ref="playlistsContainer"
      class="grid gap-4 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6"
      :class="playlist && 'absolute opacity-0 pointer-events-none'"
    >
      <div
        v-for="(currentPlaylist, index) in playlists.items"
        ref="playlistElements"
        :key="index"
        class="bg-white text-slate-800 rounded flex flex-col overflow-hidden"
      >
        <div class="h-30">
          <img
            :src="currentPlaylist?.images?.[0]?.url"
            class="w-full h-full object-cover"
          />
        </div>
        <div class="px-4 py-2 flex-grow">
          <div
            class="text-ellipsis whitespace-nowrap overflow-hidden font-bolder text-lg"
          >
            {{ currentPlaylist.name }}
          </div>
          <div
            class="text-ellipsis whitespace-nowrap overflow-hidden text-slate-500"
          >
            {{ currentPlaylist.description }}
          </div>
        </div>
        <simple-button
          v-if="!playlist"
          primary
          class="mx-4 my-2"
          @click="selectPlaylist(currentPlaylist, index)"
        >
          Select
        </simple-button>
      </div>
    </div>
    <simple-button v-if="playlists && !playlist" @click="logout"
      >Log out
    </simple-button>
    <div v-if="playlist" class="grid gap-4 grid-cols-[300px_1fr]">
      <div class="z-10">
        <simple-button
          class="w-full mb-4"
          icon="arrow-left"
          @click="closePlaylist"
        >
          Back
        </simple-button>
        <div ref="targetPlaylistContainer"></div>
        <div
          v-if="tracks"
          class="bg-white text-slate-800 rounded flex flex-col overflow-hidden mt-4 p-4"
        >
          <div class="text-lg flex gap-1">
            Focus on the
            <drop-down
              class="inline border-b border-dotted border-black"
              unstyled
              v-model="focus"
              :options="options"
            ></drop-down>
          </div>
          <simple-button
            primary
            :loading="loadingImage"
            icon="file-image"
            class="w-full mt-4"
            @click="generateCover"
          >
            Generate Cover
          </simple-button>
        </div>
        <img v-if="image" :src="image" alt="" srcset="" class="mt-4 w-full" />
        <simple-button
          v-if="image"
          primary
          :loading="sendingImage"
          icon="file-arrow-up"
          class="w-full mt-4"
          @click="useImage"
        >
          Use Image
        </simple-button>
      </div>
      <track-list :tracks="tracks" />
    </div>
  </main>
</template>

<style scoped></style>
