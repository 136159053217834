import './assets/main.css'
import 'floating-vue/dist/style.css'

import { createApp } from 'vue'
import App from './App.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import icons from './utils/icons'
import { vClosePopper, Dropdown } from 'floating-vue'

library.add(...icons)

const app = createApp(App)

app.component('fa-icon', FontAwesomeIcon)

app.directive('close-popper', vClosePopper)

app.component('VDropdown', Dropdown)

app.mount('#app')
