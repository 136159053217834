<script setup lang="ts">
import type { Page, PlaylistedTrack, Track } from '@spotify/web-api-ts-sdk'
import { gsap } from 'gsap'
defineProps<{
  tracks: Page<PlaylistedTrack<Track>> | undefined
}>()

function onEnter(el: Element, done: () => void) {
  const index = (el as HTMLElement)?.dataset?.index
  gsap.to(el, {
    opacity: 1,
    transform: 'translateY(0)',
    height: '2.5rem',
    delay: (index ? Number(index) : 1) * 0.075,
    onComplete: done
  })
}

function loadingWidth() {
  const width = Math.floor(Math.random() * 10 + 10)
  return `width: ${width}rem`
}
</script>

<template>
  <div class="bg-white text-slate-800 rounded flex flex-col p-4 gap-2">
    <transition-group v-if="tracks" appear mode="in-out" @enter="onEnter">
      <div
        v-for="(track, index) in tracks?.items"
        :key="track.track.id"
        :data-index="index"
        class="shadow-[0_0_3px_0_rgba(0,0,0,0.3)] rounded flex overflow-hidden h-0 opacity-0 translate-y-2"
      >
        <img :src="track.track.album.images[0].url" alt="" class="h-10" />
        <div class="p-2 text-ellipsis overflow-hidden whitespace-nowrap">
          {{ track.track.name }}
        </div>
      </div>
    </transition-group>
    <template v-else>
      <div
        v-for="i in 3"
        :key="i"
        class="shadow-[0_0_3px_0_rgba(0,0,0,0.3)] rounded flex overflow-hidden"
      >
        <div
          class="h-10 w-10 bg-slate-200 animate-pulse"
          :style="`animation-delay: ${i * 125}ms`"
        ></div>
        <div
          class="mx-2 my-3 h-4 rounded animate-pulse bg-slate-200"
          :style="`animation-delay: ${i * 125}ms; ${loadingWidth()}`"
        ></div>
      </div>
    </template>
  </div>
</template>

<style scoped></style>
