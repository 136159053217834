import type { GenerateType, QueryParams } from '@/interfaces/generate'
import { responseToDataUri } from '@/utils/utils'
import type {
  Artist,
  Page,
  PlaylistedTrack,
  SimplifiedPlaylist,
  Track
} from '@spotify/web-api-ts-sdk'
import { computed, type Ref } from 'vue'

export function useGenerate(
  playlist: Ref<SimplifiedPlaylist | undefined>,
  tracks: Ref<Page<PlaylistedTrack<Track>> | undefined>,
  artists: Ref<Artist[] | undefined>,
  focus: Ref<GenerateType>
) {
  function artistFromId(id: string) {
    return artists.value?.find((artist) => artist.id === id)
  }

  const genres = computed(() => {
    const innerGenres: Record<string, number> = {}
    tracks.value?.items.forEach((item) => {
      item.track.artists.forEach((_artist) => {
        const artist = artistFromId(_artist.id)
        artist?.genres.forEach((genre) => {
          innerGenres[genre] = innerGenres[genre] ? innerGenres[genre] + 1 : 1
        })
      })
    })
    return innerGenres
  })

  const artistsList = computed(() => {
    const innerList: Record<string, number> = {}
    tracks.value?.items.forEach((item) => {
      item.track.artists.forEach((artist) => {
        innerList[artist.name] = innerList[artist.name]
          ? innerList[artist.name] + 1
          : 1
      })
    })
    return innerList
  })

  async function generateCoverImage() {
    const param: QueryParams = { generateType: focus.value }
    switch (focus.value) {
      case 'genres':
        param.genres = JSON.stringify(genres.value)
        break
      case 'title':
        param.title = playlist.value?.name ?? ''
        break
      case 'artists':
        param.artists = JSON.stringify(artistsList.value)
        break
    }
    const urlParams = Object.entries(param)
      .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
      .join('&')
    const response = await fetch(
      import.meta.env.VITE_BACKEND + '/generate?' + urlParams
    )
    return await responseToDataUri(response)
  }

  return { generateCoverImage }
}
