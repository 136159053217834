import {
  faFileArrowUp,
  type IconDefinition,
  faEllipsisVertical,
  faCircleInfo,
  faCircleNotch,
  faTimes,
  faCircle,
  faSave,
  faBold,
  faItalic,
  faFont,
  faMinus,
  faPlus,
  faRotateRight,
  faFileUpload,
  faPlug,
  faBolt,
  faSpinner,
  faArrowLeft,
  faFileImage
} from '@fortawesome/free-solid-svg-icons'

export default [
  faFileArrowUp,
  faEllipsisVertical,
  faCircleInfo,
  faCircleNotch,
  faTimes,
  faCircle,
  faSave,
  faBold,
  faItalic,
  faFont,
  faMinus,
  faPlus,
  faRotateRight,
  faFileUpload,
  faPlug,
  faBolt,
  faSpinner,
  faArrowLeft,
  faFileImage,
  faFileArrowUp
] as IconDefinition[]
